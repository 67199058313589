import {SET_STORE_PRODUCT} from '../../mutation-types';

import {RepositoryFactory} from "../../../repository/RepositoryFactory";
const productRepository = RepositoryFactory.get("product");

export async function setStoreProducts({ commit }) {
    try {
        let products = [];
        let response = await productRepository.getAll();
        if (response.data.meta.success) {
            products = response.data.data;
        }
        commit(SET_STORE_PRODUCT, { products });
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        commit(SET_STORE_PRODUCT, { products: [] });
    }
}
