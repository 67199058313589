import Repository from "./Repository";
const resources = "/api/v1/banners";

export default {
    getAll() {
        return Repository.get(`${resources}`)
    },
    saveBanner(banner) {
        return Repository.post(`${resources}`, banner);
    },
    deleteBanner(id) {
        return Repository.delete(`${resources}/${id}`)
    }
}