import Vuex from 'vuex'
import Vue from 'vue'
import authenticate from './modules/Authenticate'
import erpProduct from './modules/ERPProducts'
import storeProduct from './modules/StoreProducts'

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        authenticate,
        erpProduct,
        storeProduct
    }
});

export default store;