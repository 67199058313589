import Vue from 'vue'
import App from './App.vue'
import VueSelectImage from 'vue-select-image'
import store from './store'
import router from './routes'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import 'vue-select-image/dist/vue-select-image.css'

Vue.config.productionTip = false;
Vue.config.silent = true;
Vue.use(Antd);
Vue.use(VueSelectImage);

new Vue({
  render: h => h(App),
  store,
  router
}).$mount('#app');
