import Repository from "./Repository";
const resources = "/api/v1/categories";

export default {
    getAll() {
        return Repository.get(`${resources}`)
    },
    getCategoryById(id) {
        return Repository.get(`${resources}/${id}`)
    },
    getAllParents() {
        return Repository.get(`${resources}/parents`)
    },
    addCategory(category) {
        return Repository.post(`${resources}`, category)
    },
    updateCategory(id, newCate) {
        return Repository.put(`${resources}/${id}`, newCate)
    },
    updateAllCategories(categories) {
        return Repository.put(`${resources}`, categories)
    },
    deleteCategory(id) {
        return Repository.delete(`${resources}/${id}`);
    },
    getProductsByPage({pageable, categoryId}) {
        return Repository.get(`${resources}/${categoryId}/products/p`, {
            params: pageable
        });
    },
    getProducts({categoryId}) {
        return Repository.get(`${resources}/${categoryId}/products`);
    },
    getProductsMin({categoryId}) {
        return Repository.get(`${resources}/${categoryId}/products/min`);
    }
}