import {AUTHENTICATE_SET_USER, AUTHENTICATE_SET_PERMISSIONS, AUTHENTICATE_SET_CREDENTIALS} from '../../mutation-types';
import axios from 'axios';
import config from '../../../configs/ApplicationConfig';

export default {
    async setUser({ commit }) {
        const { data: user } = await axios.get(config.authorEndpoint + "/api/permissions/salons");
        commit(AUTHENTICATE_SET_USER, { user });
    },

    async setCredentials({ commit }, {credentials}) {
        commit(AUTHENTICATE_SET_CREDENTIALS, {credentials})
    },

    async setUserPermissions({commit}) {
        try {
            const data = await axios.get( config.authorEndpoint + "/api/permissions/menus");
            const up = data.data.find( item  => item.meta.title.toUpperCase().includes("DASHBOARD") && item.meta.title.toUpperCase().includes("STORE"));
            const userPermissions = up ? up.children : [];
            localStorage.setItem("userPermissions", JSON.stringify(userPermissions))
            commit(AUTHENTICATE_SET_PERMISSIONS, { userPermissions: userPermissions })
        } catch (e) {
            localStorage.setItem("userPermissions", JSON.stringify([]))
            commit(AUTHENTICATE_SET_PERMISSIONS, { userPermissions: [] })
        }
    },
}