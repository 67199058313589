import Repository from "./Repository";
const resources = "/api/v1/groups";

export default {
    getAll() {
        return Repository.get(`${resources}`)
    },
    addGroup(group) {
        return Repository.post(`${resources}`, group)
    },
    updateGroup(id, newGroup) {
        return Repository.put(`${resources}/${id}`, newGroup)
    },
    updateAllGroups(groups) {
        return Repository.put(`${resources}`, groups)
    },
    getGroupById(id) {
        return Repository.get(`${resources}/${id}`)
    },
    getProductsByGroupId(id) {
            return Repository.get(`${resources}/${id}/products`)
    },
    addProductsToGroup({groupId, productGroups}) {
        return Repository.post(`${resources}/${groupId}/products`, productGroups)
    },
    deleteProducts({groupId, productIds}) {
        return Repository.delete(`${resources}/${groupId}/products`, {
            params: {
                productIds: productIds.join(',')
            }
        })
    }
}