import Repository from "./Repository";
const resources = "/api/v1/campaigns";

export default {
    getAll() {
        return Repository.get(`${resources}`)
    },

    getCampaign(campaignId) {
        return Repository.get(`${resources}/${campaignId}`);
    },

    getCampaignDetail(campaignId) {
        return Repository.get(`${resources}/${campaignId}/products`);
    },

    createCampaign(campaign) {
        return Repository.post(`${resources}`, campaign)
    },

    updateCampaign(id, newCampaign) {
        return Repository.put(`${resources}/${id}`, newCampaign)
    },

    deleteCampaign(id) {
        return Repository.delete(`${resources}/${id}`)
    }
}