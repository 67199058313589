import Vue from 'vue'
import Router from 'vue-router'
import { match } from 'path-to-regexp'
import App from '../App';
const ProductManagerAdmin = () => import('../pages/ProductManagerAdmin')
const Order = () => import('../pages/Order/Order')
const Dashboard = () => import('../pages/Dashboard/Dashboard')
const Category = () => import('../pages/Category/Category')
const Product = () => import('../pages/Product/Product')
const ProductTag = () => import('../pages/Product/ProductTag')
const Gallery = () => import('../pages/Gallery/Gallery')
const ProductForm = () => import('../pages/Product/ProductForm')
const ProductList = () => import('../pages/Product/ProductList')
const ProductDetails = () => import('../pages/Product/ProductDetails')
const Group = () => import('../pages/Group/Group')
const GroupList = () => import('../pages/Group/GroupList')
const ProductGroupList = () => import('../pages/Group/ProductGroupList')
const Banner = () => import('../pages/Banner/Banner');
const Page403 = () => import('../pages/Error/Page403');
const Page404 = () => import('../pages/Error/Page404');

Vue.use(Router)

let router = new Router({
    routes: [
        {
            path: '/',
            component: App,
            children: [
                {
                    path: '',
                    component: ProductManagerAdmin,
                    children: [
                        {
                            path: '',
                            name: 'Dashboard',
                            component: Dashboard,
                        },
                        {
                            path: 'products',
                            component: Product,
                            meta: {
                                requireAuth: true
                            },
                            children: [
                                {
                                    path: '',
                                    name: 'Danh sách sản phẩm',
                                    component: ProductList,
                                    meta: {
                                        requireAuth: true
                                    }
                                },
                                {
                                    path: 'category/:categoryId',
                                    name: 'Danh sách sản phẩm',
                                    component: ProductList,
                                    meta: {
                                        requireAuth: true
                                    }
                                },
                                {
                                    path: 'categories',
                                    name: 'Danh mục sản phẩm',
                                    component: Category,
                                    meta: {
                                        requireAuth: true
                                    }
                                },
                                {
                                    name: 'product-form',
                                    path: 'add-new/form',
                                    component: ProductForm,
                                    meta: {
                                        requireAuth: true
                                    }
                                },
                                {
                                    path: 'tags',
                                    name: 'Danh sách thẻ',
                                    component: ProductTag,
                                    meta: {
                                        requireAuth: true
                                    }
                                },
                                {
                                    path: ':productId',
                                    name: 'Thông tin sản phẩm',
                                    component: ProductDetails,
                                    meta: {
                                        requireAuth: true
                                    }
                                }
                            ]
                        },
                        {
                            path: 'groups',
                            name: 'Nhóm sản phẩm',
                            component: Group,
                            meta: {
                                requireAuth: true
                            },
                            children: [
                                {
                                    path: '',
                                    name: 'Danh sách',
                                    component: GroupList,
                                    meta: {
                                        requireAuth: true
                                    }
                                },
                                {
                                    path: ':groupId/products',
                                    name: 'Danh sách sản phẩm',
                                    component: ProductGroupList,
                                    meta: {
                                        requireAuth: true
                                    }
                                }
                            ]
                        },
                        {
                            path: 'orders',
                            component: Order,
                            name: 'Đơn hàng',
                            meta: {
                                requireAuth: true
                            }
                        },
                        {
                            path: 'banners',
                            component: Banner,
                            name: 'Banners',
                            meta: {
                                requireAuth: true
                            }
                        },
                        {
                            path: 'gallery',
                            component: Gallery,
                            name: 'Thư viện',
                            meta: {
                                requireAuth: true
                            }
                        },
                        {
                            path: '403',
                            component: Page403
                        },
                        {
                            path: '*',
                            component: Page404
                        }
                    ]
                }
            ]
        }
    ],
    mode: 'history',
    linkActiveClass: "active", // active class for non-exact links.
    linkExactActiveClass: "exact-active" // active class for *exact* links.
});

router.beforeEach((to, from, next) => {
    let userPermissionString = localStorage.getItem("userPermissions");
    const userPermissions = JSON.parse(userPermissionString);
    
    if (to.matched.some(record => record.meta.requireAuth) &&
        !userPermissions.some( permission => {
            let checkPath = match(permission.pageId, { decode: decodeURIComponent });
            return checkPath(to.fullPath)
        })) {
        next("/403");
    } else {
        next();
    }
});

export default router;