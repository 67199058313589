export function getCredentials(state) {
    return state.credentials;
}

export function getUser(state) {
    return state.user;
}

export function getPermissions(state) {
    return state.userPermissions;
}