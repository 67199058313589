import Repository from "./Repository";
const resources = "/api/v1/images";

export default {
    getAll() {
        return Repository.get(`${resources}`)
    },

    getAllByPage({ page }) {
        return Repository.get(`${resources}/p`, {
            params: page
        })
    },

    addImages({ images }) {
        return Repository.post(`${resources}/ls`, images)
    },

    addImage({image}) {
        return Repository.post(`${resources}`,image)
    },

    deleteImage({id}) {
        return Repository.delete(`${resources}/${id}`)
    },

    updateImage({id, newImage}) {
        return Repository.put(`${resources}/${id}`,newImage)
    }
}