<template>
    <a-layout class="App">
        <router-view v-if="isAuthenticated"></router-view>
    </a-layout>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import http from 'http';
    import https from 'http';
    import axios from "axios";
    import axiosRepository from '@/repository/Repository';
    import config from './configs/ApplicationConfig';

    axios.defaults.httpAgent = new http.Agent({ keepAlive: true });
    axios.defaults.httpsAgent = new https.Agent({ keepAlive: true });

    export default {
        name: "app",
        data() {
            return {
                isAuthenticated: false
            };
        },
        methods: {
            ...mapActions('authenticate',[
                'setUser', 'setUserPermissions','setCredentials'
            ]),
            ...mapActions('erpProduct', [
                'setErpProducts'
            ]),
            async refreshToken() {
                let { credentials } = this.getCredentials;
                const { data } = await axios.put("https://api-staff.30shine.com/api/login/refresh-token", null, {
                    headers: {
                        accessToken: credentials.AccessToken,
                        idToken: credentials.IdToken,
                        refreshToken: credentials.RefreshToken
                    }
                });

                axiosRepository.defaults.headers.common["Authorization"] = "Bearer " + data.accessToken;
                this.setCredentials({
                    credentials: {
                        ...credentials,
                        AccessToken: data.accessToken
                    }
                });
                return data;
            },
        },
        computed: {
            ...mapGetters('authenticate', [
                'getCredentials'
            ])
        },

        async created() {
            try {
                //eslint-disable-next-line no-undef
                const credentials = await ssoLogin();
                axios.defaults.headers.common["Authorization"] = "Bearer " + credentials.AccessToken;
                axiosRepository.defaults.headers.common["Authorization"] = "Bearer " + credentials.AccessToken;

                axiosRepository.interceptors.response.use( response => {
                    return response;
                }, async error => {
                    const originalRequest = error.config;
                    if (error.response.status === 401 && originalRequest.url.includes(config.baseEndpoint)) {

                        let data = await this.refreshToken();

                        originalRequest.headers = {
                            'Authorization': 'Bearer ' + data.accessToken
                        };
                        return axiosRepository(originalRequest);
                    }

                    return Promise.reject(error);
                });

                this.setCredentials({credentials});
                await this.setUser();
                await this.setUserPermissions();
                this.isAuthenticated = true;
            } catch (e) {
                // eslint-disable-next-line no-console
                console.log(e.message)
            }
        },

        async destroyed() {
            localStorage.removeItem("userPermissions");
        }
    };
</script>

<style lang="scss">
    .App {
        background-color: #31343d;
    }
    .ql-editor {
        max-height: 600px;
        overflow: auto;
    }
</style>
