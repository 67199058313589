import Repository from "./Repository";
const resources = "/api/v1/products";

export default {
    getAll() {
        return Repository.get(`${resources}/min`)
    },

    getProductsByPage({pageable}) {
        return Repository.get(`${resources}/p`, {
            params: pageable
        })
    },

    getProductById({id}) {
        return Repository.get(`${resources}/${id}`)
    },

    addNewProduct({product}) {
        return Repository.post(`${resources}`, product);
    },

    updateProductById({id, product}) {
        return Repository.put(`${resources}/${id}`, product)
    },

    deleteProduct({ productId }) {
        return Repository.delete(`${resources}/${productId}`)
    }
}