export default {
    credentials: {
        AccessToken: '',
        IdToken: '',
        RefreshToken: ''

    },
    user: {
        dateTimeServer: '',
        fullName: '',
        listSalon: '',
        userId: ''
    },
    userPermissions: []
}
