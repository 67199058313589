import Repository from "./Repository";
import config from '../configs/ApplicationConfig';
const resources = "api/v1/products";

export default {
    search(value) {
        return Repository.get(`${config.erpProductEndPoint}/${resources}/s`, {
            params: {
                search: value
            }
        });
    },

    getProductById({ id }) {
        return Repository.get(`${config.erpProductEndPoint}/${resources}/${id}`);
    },

    getProductsByPage({ page, size, sort }) {
        return Repository.get(`${config.erpProductEndPoint}/${resources}/p`, {
            params: {
                page, size, sort
            }
        });
    },

    getAll() {
        return Repository.get(`${config.erpProductEndPoint}/${resources}`)
    }
}