import Repository from "./Repository";

const resources = "/api/v1/orders";
const product = "/api/v1/products";
const status = "/api/v1/orderStatus";

// const resources = "http://vunv3-test.30shine.org/api/v1/orders";
// const product = "http://vunv3-test.30shine.org/api/v1/products";
// const status = "http://vunv3-test.30shine.org/api/v1/orderStatus";

export default {
    getAllOrder(params) {
        return Repository.get(`${resources}/p`, { params })
    },
    getDetailOrder(params) {
        return Repository.get(`${resources}/` + params)
    },
    getAllProductErp(params) {
        return Repository.get(`${product}/p`, { params })
    },
    createOrder(data) {
        return Repository.post(`${resources}`, { ...data })
    },
    updateOrder(data) {
        return Repository.put(`${resources}/${data.id}`, data)
    },
    updateStatus(data) {
        return Repository.put(`${resources}/sub`, data)
    },
    getHistory(id) {
        return Repository.get("/api/v1/orderstatushistory/order/" + id)
    },
    getListStatus() {
        return Repository.get(status)
    },
    getCustomerInfo({phone}) {
        return Repository.get("https://api-timeline.30shine.com/api/customer", {
            params: {
                phone
            }
        });
    }
    // deleteCategory(id) {
    //     return Repository.delete(`${resources}/${id}`);
    // }
}