import categoryRepository from "./categoryRepository";
import campaignRepository from "./campaignRepository";
import imageRepository from "./imageRepository";
import erpProductRepository from "./erpProductRepository";
import tagRepository from "./tagRepository";
import productRepository from "./productRepository";
import orderRepository from "./orderRepository";
import groupRepository from "./groupRepository";
import bannerRepository from "./bannerRepository";

const repositories = {
    category: categoryRepository,
    campaign: campaignRepository,
    image: imageRepository,
    erpProduct: erpProductRepository,
    tag: tagRepository,
    product: productRepository,
    order: orderRepository,
    group: groupRepository,
    banner: bannerRepository
}

export const RepositoryFactory = {
    get: name => repositories[name]
}